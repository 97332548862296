/**-------------------------------------------------------------------------------------------
                                      CLIXSIGN API HANDLER
                            Handles all calls between the data layer and front end
--------------------------------------------------------------------------------------------**/

/**-------------------------------------------------------------------------------------------
                                    IMPORTS & CONSTANTS
--------------------------------------------------------------------------------------------**/
import axios from "axios";
// import NProgress from "nprogress"; //Progress bar plugin at top of page

/**-------------------------------------------------------------------------------------------
                                      API DEFAULTS
--------------------------------------------------------------------------------------------**/
//Set Axios Default Config
var baseUrl =
  process.env.NODE_ENV === "development"
    ? "staging-api.mycreditdata.io"
    : "api.mycreditdata.io";

baseUrl = "https://" + baseUrl;

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common["Content-Type"] =
  "application/json; charset=utf-8";
axios.defaults.headers.common["Accept-Language"] = "en-US";
axios.defaults.headers.common["Accept"] =
  "application/json;q=.09;utf-8;2.0,*/*;q=.08";

/**-------------------------------------------------------------------------------------------
                                  API LOADING
--------------------------------------------------------------------------------------------**/
/* NProgress.configure({ easing: 'ease', speed: 1000, showSpinner: true });
axios.interceptors.request.use(config => {
  NProgress.start();
  return config;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  NProgress.done();
  return response;
}, error => {
  return Promise.reject(error);
}); */

export default axios;
