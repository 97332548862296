/** -------------------------------------------------------------------------------------------
                                    DPP API CONFIG FILE
                Contains all routes and settings to access the spinwheel api
--------------------------------------------------------------------------------------------**/

import apiConfig from '@/api/api-config'
import $store from '@/store.js'
const api = apiConfig // Binds the axios base instance in the import and makes it available to the endpoints below

/** -------------------------------------------------------------------------------------------
                                        ROUTES & PATHS
                            List of API Endpoints. Exposed as this.$api
--------------------------------------------------------------------------------------------**/
export default {

  sendEvent: function (event) {
    event.extId = $store.tokenData.extUserId;
    return api.post('events', event)
  },
  getToken: function (token) {
    return api.post('token', token)
  }
}
