<template>
  <Header />
  <div class="home bg-white flex flex-col h-screen">
    <div class="flex flex-col justify-center content items-center mt-2">
      <div class="content-box">
        <h3>Hi {{tokenData.firstName}} {{tokenData.lastName}},</h3>
        <p>
          Thanks for working with {{tokenData.companyName}}!
          We look forward to assisting you on your path to financial freedom.
        </p>
        <p></p>
        <p>
          To begin the process we must first verify your identity and receive your approval to pull your credit report.
        </p>
      </div>
      <div class="content-box">
        <h3>What is Forth Credit?</h3>
        <p>
          We have partnered with Forth Credit to perform identity verification and credit pull processes.
          Your personal information is encrypted, safe and secure throughout the process.
        </p>
      </div>
      <div class="content-box">
        <h3>How does the 2 Step Verification process work?</h3>
      </div>
      <div class="content-card">
        <div class="content-card-up">
          <img alt="404 img" src="../assets/id-card.svg" class='icon'/><h4> Enter your information</h4>
        </div>
        <div class="content-card-down">
          <p>We will ask you to enter your phone number and date of birth.</p>
        </div>
      </div>
      <div class="content-card">
        <div class="content-card-up">
          <img alt="404 img" src="../assets/badge-check.svg" class='icon'/><h4> Verify your information</h4>
        </div>
        <div class="content-card-down">
          <p>You will receive a link via SMS to confirm your information and provide consent to pull credit.</p>
        </div>
      </div>
      <div class="flex flex-col justify-center bottom-8 items-center button-div">
        <button @click="verify" type="submit" class="continue-button flex font-avenir items-center justify-center w-64 h-12
            mt-2 py-2 ml-8 mr-4 px-4 drop-shadow-md border border-transparent shadow-md text-xxl rounded-md
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-midnight
            text-white"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import $store from '@/store.js'
import Header from '@/components/Header.vue'

export default {
  name: 'Landing',
  components: {
    Header
  },
  data() {
    return {
      defaultColor: '#06699c',
      tokenData: $store.tokenData
    }
  },
  mounted () {
  },
  methods: {
    verify() {
      console.log('page for verification');
      this.$router.push({name: "verification", });
    }
  }
}
</script>
<style scoped>
html {
  @apply text-gray-900;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue','Arial', sans-serif;
}
p {
  @apply pt-1 text-sm font-medium leading-4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue','Arial', sans-serif;
  color:#414141;
  font-size: .875rem;
  line-height: 1.2rem;
  font-weight: 400;
  padding-top: 0.5rem;
}
h1, h2, h3, h4 {
  @apply font-avenir font-bold;
}
h3 {
  font-size: 1.125rem;
  line-height: 1.2em;
}
.content {
  padding: 1rem;
  width: 30rem;
}
@media only screen and (max-width: 26rem) {
  .content {
    padding: 1rem;
    width: 23.4375rem;
  }
}
.content-box {
  margin-bottom: 1.5rem;
  width: -webkit-fill-available;
}
.content-box p {
  margin-bottom: .2rem;
}
.content-card {
  width: 100%;
  box-shadow: 2px 4px 17px 0px rgba(65, 116, 162, 0.21);
  margin-bottom: 1.5rem;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.content-card-up {
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border: 1px solid var(--prime-vue-input-field-input-border, #D1D5DB);
  background: var(--prime-vue-root-surface-card, #FFF);
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}
.content-card-down {
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  border: 1px solid var(--prime-vue-input-field-input-border, #D1D5DB);
  background: var(--prime-vue-root-surface-card, #FFF);
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}
.home {
  align-items: center;
  padding-top: 0.3rem;
}
.loader {
  border-top-color: #3498db;
}
.icon {
  float:left;
}
.button-div {
  position: fixed;
  bottom: 0rem;
  width: 100%;
  border: 1px solid var(--prime-vue-surface-surface-300, #E0E0E0);
  background: var(--prime-vue-surface-surface-200, #EEE);
  padding: 1.5rem;
}
.continue-button {
  border-radius: 0.375rem;
  border: 1px solid #25718F;
  background: #25718F;
  width: 20rem;
}
@media only screen and (max-width: 26rem) {
  .button-div {
    bottom: 0rem;
  }
}
</style>