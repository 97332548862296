<template>
  <Header />
  <div class="home">
    <div class="page-not-found-frame bg-white flex flex-col h-screen" v-if="pageNotFound">
      <img alt="404 img" src="../assets/404.svg" class='page-not-found-img'/>
      <h2>404</h2>
      <h3 class="resource">Resource not found.</h3>
    </div>
    <div class="outage-frame bg-white flex flex-col h-screen" v-else-if="eventName == 'DIM_UNAVAILABLE_OUTAGE'">
      <img alt="outage img" src="../assets/Outage-Web.svg" class='outage-img'/>
      <h2>Service Unavailable</h2>
      <h3 class="outage-desc">The Forth Credit service is currently unavailable due to technical issues.
        Please inform the agent you are working with to receive a new link and try again.</h3>
    </div>
    <div class="outage-frame bg-white flex flex-col h-screen" v-else-if="eventName == 'EXPIRED_TOKEN'">
      <img alt="outage img" src="../assets/Expired.svg" class='outage-img'/>
      <h2>This link has expired</h2>
      <h3 class="outage-desc">Please contact your Debt Relief Service Provider to receive a new link and try again.</h3>
    </div>
    <div class="failed-frame bg-white flex flex-col h-screen" v-else>
      <img alt="failed img" src="../assets/Failed_Verification.svg" class='failed-img'/>
      <h2>Uh-Oh, Verification Failed</h2>
      <h3>We were unable to verify your<br>identity with the details provided.</h3>
    </div>
  </div>
</template>


<script>
import Header from '@/components/Header.vue'
import $store from "@/store";
export default {
  name: 'Error',
  components: {
    Header
  },
  data() {
    return {
      pageNotFound: 0,
      eventName: $store.metadata.eventName
    }
  },
  mounted() {
    this.pageNotFound = $store.pageNotFound;
    this.eventName = $store.metadata.eventName;
  },
  methods: {

  }
}
</script>
<style scoped>
html {
  @apply text-gray-900;
}
p {
  @apply pt-1 text-sm font-medium leading-4;
}
h2 {
  @apply font-avenir font-bold;
}
h3 {
  @apply pt-2 font-avenir font-bold text-lg;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue','Arial', sans-serif;
}
a {
  @apply text-sm text-blue-700 no-underline hover:underline leading-none
}
br {
  @apply leading-none
}
.home {
  justify-content: center;
  display: flex;
  text-align: center;
}
.failed-frame, .page-not-found-frame, .outage-frame{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 0.29206rem;
  width: 23.4375rem;
  margin-top: 3rem;
}
.failed-img, .page-not-found-img {
  width: auto;
  height: 23rem;
}
.outage-img {
  height: 16rem;
}
.outage-desc {
  width: 26rem;
}
h2 {
  color: var(--tailwind-forth-charcoal-800, #202020);
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h3 {
  color: var(--tailwind-forth-charcoal-600, #515151);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.resource {
  font-size: 1.3rem;
}
.outage-desc {

}
@media only screen and (max-width: 26rem) {
  .resource {
    font-size: 1.5rem;
  }
  .failed-img, .page-not-found-img {
    height: 14rem;
  }
  .outage-img{
    height: 8rem;
    margin-bottom: 1.5rem;
  }
  .outage-desc {
    width: 21rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}
</style>