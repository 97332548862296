<template>
  <Header />
  <div class="home">
    <div id="spinwheel-debt-connect" class="dim"></div>
  </div>
</template>

<script>
import api from '@/api/api.js'
import $store from '@/store.js'
import Header from '@/components/Header.vue'

export default {
  name: 'DebtConnect',
  components: {
    Header
  },
  data() {
    return {
    }
  },
  mounted () {
    if (this.$route.params.guid2) {
      api.getToken({token: this.$route.params.guid2}).then((res) => {
        console.log('token');
        $store.tokenData = res.data.response;
        this.loadDIM();
      });
    } else {
      this.loadDIM();
    }
  },
  methods: {
    async sendEvent(event) {
      try {
        await api.sendEvent(event)
      } catch (ex) {
        console.log(ex);
        $store.pageNotFound = 1;
        this.$router.push({ name: "error", });
      }
    },
    loadDIM() {
      const config = {
        containerId: 'spinwheel-debt-connect',
        onSuccess: (metadata) => {
          console.log('onSuccess', metadata);
          this.sendEvent(metadata);
          this.$router.push({name: "success", });
        },
        onLoad: (metadata) => {
          console.log('onLoad', metadata);
          this.sendEvent(metadata);
        },
        onEvent: (metadata) => {
          console.log('onEvent', metadata);
          this.sendEvent(metadata);
          if (metadata.eventName == 'AUTH_SUCCESS') {
            this.$router.push({name: "success", });
          }
        },
        onError: (metadata) => {
          console.log('onError', metadata);
          this.sendEvent(metadata);
          this.$router.push({ name: "error", });
        },
        dropinConfig: {
          module: 'debt-connect',
          token: $store.tokenData.token,
          userPersonalInfo: {
            firstName: $store.tokenData.firstName,
            lastName: $store.tokenData.lastName,
            ssn: $store.tokenData.ssn,
            dob: $store.tokenData.dob + "T12:00:00",
            addressLine1: $store.tokenData.address,
            addressLine2: $store.tokenData.address2,
            addressCity: $store.tokenData.city,
            addressState: $store.tokenData.state,
            addressZip: $store.tokenData.zip
          }
        },
      };
      const handler = window.Spinwheel && window.Spinwheel.create(config);
      handler.open();
    }
  }
}
</script>
<style scoped>
html {
  @apply text-gray-900;
}
p {
  @apply pt-1 text-sm font-medium leading-4;
}
h1 {
  @apply font-avenir font-bold;
}
.loader {
  border-top-color: #3498db;
}
.home {
  justify-content: center;
  display: flex;
}
.dim {
  width: 33rem;
  height: 40rem;
}
.content {
  padding: 1rem;
  width: 30rem;
}
</style>