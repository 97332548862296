<template>
  <Header />
  <div class="home">
    <div class="success-frame bg-white flex flex-col h-screen">
      <img alt="fingerprint img" src="../assets/Success-Illustration.svg" class='success-img'/>
      <h2>Woohoo!</h2>
      <h3 class="text-green-600">Thank you for verifying your identity & <br> providing consent to pull your credit report. </h3>
      <h3 class="">No further action is needed from you. <br> You may close this page</h3>
    </div>
  </div>
</template>


<script>
import Header from '@/components/Header.vue'
export default {
  name: 'Error',
  components: {
    Header
  },
  data() {
  },
  mounted() {

  },

  methods: {
  }
}
</script>
<style scoped>
html {
  @apply text-gray-900;
}
p {
  @apply pt-1 text-sm font-medium leading-4;
}
h2 {
  @apply font-avenir font-bold;
  font-size: 25px;
}
h3 {
  @apply pt-2 font-avenir font-bold text-lg;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue','Arial', sans-serif;
}
a {
  @apply text-sm text-blue-700 no-underline hover:underline leading-none
}
br {
  @apply leading-none
}
.home {
  justify-content: center;
  display: flex;
  text-align: center;
}
.success-frame {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 0.29206rem;
  width: 23.4375rem;
  margin-top: 3rem;
}
.success-img {
  width: 15rem;
  height: 14rem;
  transform: rotate(-3.726deg);
}
@media only screen and (max-width: 26rem) {
  .success-img {
    width: 12.57069rem;
    height: 10.8565rem;
    transform: rotate(-3.726deg);
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 14px;
  }
}
</style>