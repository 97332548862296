<template>
  <Header />
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Token',
  components: {
    Header
  },
}
</script>
<style scoped>
html {
  @apply text-gray-900;
}
p {
  @apply pt-1 text-sm font-medium leading-4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue','Arial', sans-serif;
}
h1, h2, h3, h4 {
  @apply font-avenir font-bold;
}
.content {
  padding: 1rem;
  width: 30rem;
}
</style>