<template>
  <div class="container justify-center items-center flex">
    <div class="header-div absolute">
      <div class="w-full bg-white justify-center items-center top-0 blue-header"></div>
    </div>
    <div class="flex justify-center items-center text-white absolute header-contents">
      <img alt="logo img" src="../assets/fingerprint.svg" class='mr-3 max-h-14 max-w-[40%]'/>
      <div style="display:flex; flex-direction:column">
        <span class='company-name font-light'>{{ tokenData ? tokenData.companyName : '' }}</span>
        <p class='text-sm font-light'><span class="italic">via</span> <span class="font-bold">Forth Credit </span><span class="italic">powered by</span> <span class="font-bold">Spinwheel</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import $store from '@/store.js'
import api from '@/api/api.js'

export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
      defaultColor: '#06699c',
      tokenData: $store.tokenData
    }
  },
  mounted () {
    if (this.$route.params.guid) {
      $store.guid = this.$route.params.guid
      this.getToken()
    }
    this.tokenData = $store.tokenData
  },
  computed: {
    makeGradient() {
      const baseColor = this.defaultColor
      const darkColor = baseColor ? this.changeLuminosity(baseColor, -0.5) : ''
      return `background-image: linear-gradient(0deg, ${ darkColor } -10.87%, ${ baseColor } 130.94%)`
    },
  },
  methods: {
    changeLuminosity(hex, lum) {
      let rgb = "#"
      let c
      hex = hex.substr(1)
      lum = lum || 0
      for (let i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i*2,2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00"+c).substr(c.length);
      }
      return rgb;
    },
    async getToken() {
      try {
        const data = {
          token: $store.guid
        }
        const response = await api.getToken(data);
        console.log(1);

        this.tokenData = response.data.response;
        $store.tokenData = response.data.response;

        this.$router.push({ name: "welcome", });

      } catch (ex) {
        console.log('failed to get token.');
        this.$router.push({ name: "error", });
      }
    }
  }
}
</script>
<style scoped>
html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue','Arial', sans-serif;
}
.company-name {
  font-size: 1rem;
  font-style: normal;
  margin-bottom: -0.3rem;
}
.blue-header {
  background:url("../assets/header-bg-web.jpg");
  background-size:100%;
  opacity: 0.9;
  height: 100%;
}
.header-div {
  background:linear-gradient(0deg, #0B4855 0%, #25718F 46.88%, #43A1D1 100%);
  width: 100%;
  height: 100%;
}
.container {
  position: relative;
  height: 7rem;
  max-width: 100%;
}
.header-contents {
  height: 7rem;
}
@media only screen and (max-width: 26rem) {
  .blue-header {
    background:url("../assets/header-bg-mobile.jpg");
    background-size: cover;
  }
}
</style>