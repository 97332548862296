import { createRouter } from "vue-router";
import { createWebHistory } from "vue-router";
import $store from '@/store.js'

import Landing from '@/views/Landing.vue'
import Verify from '@/views/Verify.vue'
import Success from '@/views/Success.vue'
import DebtConnect from '@/views/DebtConnect.vue'
import Error from '@/views/Error.vue'
import Token from '@/views/getToken.vue'
const error = () => import('@/views/Error.vue')

/**-------------------------------------------------------------------------------------------
 ROUTES
 --------------------------------------------------------------------------------------------**/
const routes = [
    {

        path: "/:guid",
        name: "lobby",
        component: Token,
        meta: {
            title: "Welcome",
        },
    },
    {

        path: "/welcome",
        name: "welcome",
        component: Landing,
        meta: {
            title: "Welcome",
        },
    },
    {

        path: "/verify",
        name: "verification",
        component: Verify,
        meta: {
            title: "Verification",
        },
    },
    {
        path: "/verify/:guid2",
        name: "verification2",
        component: Verify,
        meta: {
            title: "Verification",
        },
    },
    {
        path: "/success",
        name: "success",
        component: Success,
        meta: {
            title: "Success",
        },
    },
    {
        path: "/debtconnect",
        name: "debtconnect2",
        component: DebtConnect,
        meta: {
            title: "Debt Connect",
        },
    },
    {
        path: "/debtconnect/:guid2",
        name: "debtconnect",
        component: DebtConnect,
        meta: {
            title: "Debt Connect",
        },
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: { name: "error" },
    },
    {
        path: "/error",
        name: "error",
        component: Error,
        meta: {
            title: "Page Not Found",
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

/**-------------------------------------------------------------------------------------------
 NAVIGATION GUARDS
 --------------------------------------------------------------------------------------------**/

router.afterEach(() => {

});
/**-------------------------------------------------------------------------------------------
 ROUTE AUTHORIZATION ENFORCEMENT
 --------------------------------------------------------------------------------------------**/

//Enforcer Function That Handles Redirects if Package Doesn't Exist
function checkGuid(to) {
    if (!$store || !$store.guid.length) {
        router.push({ name: "error", params: { issue: "guid", message: "There is a problem with your link. Please try again." } });
    }
}
//trackRouter(router); //Vue Google Analytics (VueGTAG)
export default router;
